import { Box, Card, Em, Flex, HStack, Icon, StatLabel, StatRoot, StatValueText, Text } from "@chakra-ui/react";
import React from "react";
import { LuDollarSign } from "react-icons/lu";
import { useOutletContext } from "react-router-dom";
import { Pediatrician } from "../../modules/core/models/registry/pediatrician.model";

export const PediatricianCardPage: React.FC = () => {
  // const { id } = useParams();
  const context = useOutletContext<Pediatrician | undefined>();
  return (
    <Box>
      <Text textStyle="sm" color={'gray.400'}><Em>Scheda di dettaglio del Pediatra</Em></Text>

      <Flex gap="4" marginY={4}>
        <StatRoot borderWidth="1px" p="4" rounded="md">
          <StatLabel>Pazienti</StatLabel>
          <StatValueText>1900</StatValueText>
        </StatRoot>
        <StatRoot borderWidth="1px" p="4" rounded="md">
          <StatLabel>Consulti oggi</StatLabel>
          <StatValueText>1900</StatValueText>
        </StatRoot>
        <StatRoot borderWidth="1px" p="4" rounded="md">
          <StatLabel>Certificati oggi</StatLabel>
          <StatValueText>1900</StatValueText>
        </StatRoot>
        <StatRoot borderWidth="1px" p="4" rounded="md">
          <StatLabel>Prescrizioni oggi</StatLabel>
          <StatValueText>1900</StatValueText>
        </StatRoot>
        <StatRoot borderWidth="1px" p="4" rounded="md">
          <StatLabel>SOS oggi</StatLabel>
          <StatValueText>1900</StatValueText>
        </StatRoot>
      </Flex>

      <Flex gap="4" marginY={4}>
        <Card.Root>
          <Card.Body gap="2">
            <Card.Title mt="2">Dati Anagrafici</Card.Title>
            <Card.Description>
              This is the card body. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Curabitur nec odio vel dui euismod fermentum.
              Curabitur nec odio vel dui euismod fermentum.
            </Card.Description>
          </Card.Body>
          <Card.Footer justifyContent="flex-end">
          </Card.Footer>
        </Card.Root>

        <Card.Root>
          <Card.Body gap="2">
            <Card.Title mt="2">Dati Professionali</Card.Title>
            <Card.Description>
              This is the card body. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Curabitur nec odio vel dui euismod fermentum.
              Curabitur nec odio vel dui euismod fermentum.
            </Card.Description>
          </Card.Body>
          <Card.Footer justifyContent="flex-end">
          </Card.Footer>
        </Card.Root>
      </Flex>
      
      <Flex gap="4" marginY={4}>
        <Card.Root>
          <Card.Body gap="2">
            <Card.Title mt="2">Indirizzi</Card.Title>
            <Card.Description>
              This is the card body. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Curabitur nec odio vel dui euismod fermentum.
              Curabitur nec odio vel dui euismod fermentum.
            </Card.Description>
          </Card.Body>
          <Card.Footer justifyContent="flex-end">
          </Card.Footer>
        </Card.Root>

        <Card.Root>
          <Card.Body gap="2">
            <Card.Title mt="2">Documenti</Card.Title>
            <Card.Description>
              This is the card body. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Curabitur nec odio vel dui euismod fermentum.
              Curabitur nec odio vel dui euismod fermentum.
            </Card.Description>
          </Card.Body>
          <Card.Footer justifyContent="flex-end">
          </Card.Footer>
        </Card.Root>
      </Flex>
    </Box>
  );
}
