import { AbsoluteCenter, Box, Heading, Spinner, VStack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../base/contexts/auth.context';

export const AuthCallbackPage: React.FC = () => {
  // const { id } = useParams();
  const { HandleRedirectCallback } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthCallback = async () => {
      try {
        const user = await HandleRedirectCallback();
        console.log('CB',user);
        navigate('/');
      } catch (error) {
        navigate('/auth');
        console.error('Authentication failed', error);
      }
    };
    handleAuthCallback();
  }, [HandleRedirectCallback, navigate]);
  
  return (
    <Box width={'100%'} height={'100%'} >
      <AbsoluteCenter p="4" axis="both">
        <VStack>          
          <Heading size="4xl">Kiddocare Admin Portal</Heading>
          <Spinner size={'xl'} />
        </VStack>
      </AbsoluteCenter>
    </Box>
  );
}