import ReactDOM from 'react-dom/client';
import { AppProvider } from './base/contexts/app.context';
import { AuthProvider } from './base/contexts/auth.context';
import { Main } from './pages/main.component';

const App = () => {  
  return (
    <AppProvider>
      <AuthProvider>
        <Main></Main>
      </AuthProvider>
    </AppProvider>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
/*root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);*/
root.render(<App />);