import configHelper from "../../../base/helpers/config.helper";
import Logger from "../../../base/helpers/logger.helper";


export class CoreService {
  
  public log?: Logger;
  public CoreBaseUrl: string;

  constructor() {
    this.CoreBaseUrl = configHelper.GetApiCoreUrl();
    this.log = Logger.getInstance(CoreService.name);
    this.log?.info('CORE BASE URL', this.CoreBaseUrl);
  }

  public Info(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      fetch(this.CoreBaseUrl, { 
        method: 'GET'
      }).then((response) => {
        this.log?.info('EC', response);
        if (!response.ok){ throw new Error(response.status + ' - ' + response.statusText);}
        return response.json();
      }).then(data => resolve(data)).catch((err) => {
        this.log?.error('lo cattura qui', err);
        reject(err);
      });
    });
  }
}