import React from 'react';
import { useOutletContext } from 'react-router-dom';

export const PediatriciansCertificatesPage: React.FC = () => {
  const context = useOutletContext<string>();
  return (
    <div>
      <h3>Certificati del Pediatra (ID: {context})</h3>
      {/* Logica per visualizzare le fatture */}
    </div>
  );
}
