import { Button, IconButton } from '@chakra-ui/react';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

export const ButtonLinkComponent: React.FC<{ 
  to: string, 
  children: any,
  type?: "Button" | "IconButton",
  isActiveVariant?: "solid" | "subtle" | "surface" | "outline" | "ghost" | "plain" ,
  isNotActiveVariant?: "solid" | "subtle" | "surface" | "outline" | "ghost" | "plain",
  colorPalette?: string,
  title?: string
}> = ({ 
  to, 
  children, 
  type = 'Button',
  isActiveVariant = 'surface',
  isNotActiveVariant = 'ghost',
  colorPalette = 'gray',
  title
}) => {
  const location = useLocation();
  let isActive = false;
  if (to.startsWith('/')) { isActive = location.pathname.startsWith(to);}
  else { isActive = location.pathname.endsWith(to); }
  
  console.log('---', isActive, to, location.pathname, children);

  if (type === 'Button') {
    return (
      <Button asChild variant={isActive ? isActiveVariant : isNotActiveVariant} w="full" justifyContent="start" colorPalette={colorPalette} title={title}>
        <NavLink to={to}>{children}</NavLink>
      </Button>    
    );
  } else if (type === 'IconButton') {
    return (
      <IconButton asChild variant={isActive ? isActiveVariant : isNotActiveVariant} colorPalette={colorPalette} title={title}>
        <NavLink to={to}>{children}</NavLink>
      </IconButton>    
    );
  }
};


/*

<Button
      as={RouterLink}
      to={to}
      variant="ghost"
      w="full"
      colorScheme={isActive ? 'teal' : 'gray'} // Cambia il colore se il link è attivo
      bg={isActive ? 'teal.100' : 'transparent'} // Cambia lo sfondo se il link è attivo
      _hover={{ bg: isActive ? 'teal.200' : 'gray.100' }}
    >
      <HStack spacing="2">
        <Icon as={icon} />
        <Text>{children}</Text>
      </HStack>
    </Button>
*/