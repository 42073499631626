import { createSystem, defaultConfig, defineConfig } from "@chakra-ui/react";

const myconfig = defineConfig({
  strictTokens: true,
  theme: {    
    tokens: {
      colors: {
        admin: {
          50: { value: "#FAF5F6" },
          100: { value: "#F5EBED" },
          200: { value: "#E7CFD5" },
          300: { value: "#DAB4BD" },
          400: { value: "#C992A0" },
          500: { value: "#B05F73" },
          600: { value: "#A45166" },
          700: { value: "#8F4759" },
          800: { value: "#743948" },
          900: { value: "#552A35" },
          950: { value: "#3D1E26" }
        },
        pediatrician: {
          50: { value: "#F6F7FE" },
          100: { value: "#EDEFFD" },
          200: { value: "#D7DAFA" },
          300: { value: "#C0C6F6" },
          400: { value: "#A5ADF3" },
          500: { value: "#828DEE" },
          600: { value: "#6F7CEB" },
          700: { value: "#5967E8" },
          800: { value: "#3E4FE5" },
          900: { value: "#1B2CC6" },
          950: { value: "#131F8B" }
        },
        parent: {
          50: { value: "#F1F8F5" },
          100: { value: "#E7F3ED" },
          200: { value: "#CCE6D9" },
          300: { value: "#ADD6C2" },
          400: { value: "#8BC5A9" },
          500: { value: "#5EAF88" },
          600: { value: "#50A079" },
          700: { value: "#458C6A" },
          800: { value: "#3A7458" },
          900: { value: "#29523E" },
          950: { value: "#1B3729" }
        },
        patient: {
          50: { value: "#FFF9E5" },
          100: { value: "#FFEDB8" },
          200: { value: "#FFE28A" },
          300: { value: "#FFD65C" },
          400: { value: "#FFCB2E" },
          500: { value: "#FFBF00" },
          600: { value: "#CC9900" },
          700: { value: "#997300" },
          800: { value: "#664C00" },
          900: { value: "#332600" }
        },
        danger: {
          50: { value: "#FDE9E7" },
          100: { value: "#FAC1BD" },
          200: { value: "#F79892" },
          300: { value: "#F47067" },
          400: { value: "#F0483D" },
          500: { value: "#ED2012" },
          600: { value: "#BE1A0E" },
          700: { value: "#8E130B" },
          800: { value: "#5F0D07" },
          900: { value: "#2F0604" }
        },
      }
    },
    semanticTokens: {
      colors: {
        admin: {
          solid: { value: "{colors.admin.500}" },
          contrast: { value: "{colors.admin.100}" },
          fg: { value: "{colors.admin.700}" },
          muted: { value: "{colors.admin.400}" },
          subtle: { value: "{colors.admin.200}" },
          emphasized: { value: "{colors.admin.300}" },
          focusRing: { value: "{colors.admin.500}" },
        },
        pediatrician: {
          solid: { value: "{colors.pediatrician.500}" },
          contrast: { value: "{colors.pediatrician.100}" },
          fg: { value: "{colors.pediatrician.700}" },
          muted: { value: "{colors.pediatrician.400}" },
          subtle: { value: "{colors.pediatrician.200}" },
          emphasized: { value: "{colors.pediatrician.300}" },
          focusRing: { value: "{colors.pediatrician.500}" },
        },
        parent: {
          solid: { value: "{colors.parent.500}" },
          contrast: { value: "{colors.parent.100}" },
          fg: { value: "{colors.parent.700}" },
          muted: { value: "{colors.parent.400}" },
          subtle: { value: "{colors.parent.200}" },
          emphasized: { value: "{colors.parent.300}" },
          focusRing: { value: "{colors.parent.500}" },
        },
        patient: {
          solid: { value: "{colors.patient.500}" },
          contrast: { value: "{colors.patient.100}" },
          fg: { value: "{colors.patient.700}" },
          muted: { value: "{colors.patient.400}" },
          subtle: { value: "{colors.patient.200}" },
          emphasized: { value: "{colors.patient.300}" },
          focusRing: { value: "{colors.patient.500}" },
        },
        danger: {
          solid: { value: "{colors.danger.500}" },
          contrast: { value: "{colors.danger.100}" },
          fg: { value: "{colors.danger.700}" },
          muted: { value: "{colors.danger.400}" },
          subtle: { value: "{colors.danger.200}" },
          emphasized: { value: "{colors.danger.300}" },
          focusRing: { value: "{colors.danger.500}" },
        },
      },
    }
  },
})
// util https://www.tints.dev/brand/828DEE

//const config = mergeConfigs(defaultConfig, myconfig);
//const system = createSystem(config);
const system = createSystem(defaultConfig, myconfig);
export default system;