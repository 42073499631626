import { Box, Button, Center, Em, Flex, HStack, Input, Spinner, Stack, Table, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { PiCheckCircleDuotone, PiHouseDuotone, PiMagnifyingGlassDuotone, PiPlusLight, PiStethoscopeDuotone } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import Logger from '../../base/helpers/logger.helper';
import { PaginationResponse } from '../../base/models/pagination-response.model';
import { BreadcrumbCurrentLink, BreadcrumbLink, BreadcrumbRoot } from '../../base/ui/breadcrumb';
import { DialogActionTrigger, DialogBody, DialogCloseTrigger, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from '../../base/ui/dialog';
import { PaginationItems, PaginationNextTrigger, PaginationPrevTrigger, PaginationRoot } from '../../base/ui/pagination';
import { CoreRegistryPediatricianAdd } from '../../modules/core/components/registry-pediatrician-add.component';
import { Pediatrician } from '../../modules/core/models/registry/pediatrician.model';
import { CoreRegistryPediatricansService } from '../../modules/core/services/registry/pediatricians.service';

const coreRPS = new CoreRegistryPediatricansService();
const log = Logger.getInstance('PediatriciansPage');

export const PediatriciansPage: React.FC = () => {

  const [data, setData] = useState<PaginationResponse<Pediatrician> | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isInLoading, setIsInLoading] = useState<boolean>(false);

  const loadData = () => {
    var request = { currentPage, searchTerm };
    log.debug('Loading data', request);
    setIsInLoading(true);
    coreRPS.getPediatrics().then((response) => {
      log.debug('Data loaded', response);
      setData(response);
      setIsInLoading(false);
    }).catch((err) => {
      log.error('Error loading data', err);
      setIsInLoading(false);
    });
  };

  const handleSearch = () => {
    setCurrentPage(1);
    loadData();
  };

  const handlerPageChange = (page: number) => {
    setCurrentPage(page);
    loadData();
  };
  
  useEffect(() => {
    console.log('IO');
    loadData();
  }, []);

  const formRef = useRef<{ submitForm: () => void }>(null);

  return (
    <Box px={{ base: '4', lg: '12'}} py={'6'} overflow={'auto'} w={'full'} h={'full'} position={'relative'} aria-busy="true" userSelect="none" colorPalette="pediatrician">
      <VStack offset={4} align="stretch">

        <Flex mb={4} justifyItems={'center'}>
          <Box flexGrow={1}>
            <BreadcrumbRoot separatorGap={4} size={'lg'}>
              <BreadcrumbLink asChild>
                <Link to="/">
                  <PiHouseDuotone /> Home             
                </Link>
              </BreadcrumbLink>
              <BreadcrumbCurrentLink colorPalette={'pediatrician'}>
                <HStack>
                  <PiStethoscopeDuotone /> Pediatri
                </HStack>
              </BreadcrumbCurrentLink>
            </BreadcrumbRoot>
            <Text textStyle="xs" color={'gray.400'}><Em>Lista di tutti i pediatri gestiti sul sistema</Em></Text>
          </Box>
          <Box flexGrow={0}>
          </Box>
        </Flex>

        <Flex spaceX={2}>
          <Box flexGrow={1}>
            <HStack offset={4}>              
              <Input placeholder="Cerca: nome, cognome, codice fiscale." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
              <Button onClick={handleSearch}>
                <PiMagnifyingGlassDuotone />
                Cerca
              </Button>
            </HStack>
          </Box>
          <Box>
            <DialogRoot>
              <DialogTrigger asChild>
                <Button variant="outline">
                  <PiPlusLight /> Nuovo
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Dialog Title</DialogTitle>
                </DialogHeader>
                <DialogBody>
                  <CoreRegistryPediatricianAdd ref={formRef} />
                </DialogBody>
                <DialogFooter>
                  <DialogActionTrigger asChild>
                    <Button variant="outline">Cancel</Button>
                  </DialogActionTrigger>
                  <Button onClick={() => formRef.current?.submitForm()}>Save</Button>
                </DialogFooter>
                <DialogCloseTrigger />
              </DialogContent>
            </DialogRoot>
          </Box>
        </Flex>

        <Table.ScrollArea borderWidth="1px" maxW="full" rounded="lg">
          <Table.Root size="sm" showColumnBorder>
            <Table.Header>
              <Table.Row>
                <Table.ColumnHeader>Nome</Table.ColumnHeader>
                <Table.ColumnHeader>Cognome</Table.ColumnHeader>
                <Table.ColumnHeader>Codice Fiscale</Table.ColumnHeader>
                <Table.ColumnHeader>Account Creato</Table.ColumnHeader>
                <Table.ColumnHeader>Dettaglio</Table.ColumnHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data?.items.map((pediatrician) => (
                <Table.Row key={pediatrician.id}>
                  <Table.Cell>{pediatrician.firstName}</Table.Cell>
                  <Table.Cell>{pediatrician.lastName}</Table.Cell>
                  <Table.Cell>{pediatrician.taxCode}</Table.Cell>
                  <Table.Cell>
                    {pediatrician.account ? (
                      <PiCheckCircleDuotone color={'green'} />
                    ) : (
                      <Text color={'gray.300'} >Non creato</Text>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={`/pediatricians/detail/${pediatrician.id}`}>Dettaglio</Link>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Root>
        </Table.ScrollArea>

        <Stack gap="4" mt={4} align={{ base: 'central', lg: 'end'}}>
          <PaginationRoot page={data?.page} count={data?.total ?? 0} pageSize={data?.size} onPageChange={(e) => handlerPageChange(e.page)}>
            <HStack offset={4}>
              <PaginationPrevTrigger />
              <PaginationItems />
              <PaginationNextTrigger />
            </HStack>
          </PaginationRoot>
        </Stack>
      </VStack>
      
      {(isInLoading) &&
        <Box pos="absolute" inset="0" bg="bg/80">
          <Center h="full">
            <VStack>
              <Spinner color="pediatrician.500" />
              <Text color="pediatrician.500">Recupero i pediatri...</Text>
            </VStack>
          </Center>
        </Box>
      }
    </Box>
  );
};


