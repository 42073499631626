import { AbsoluteCenter, Box, Button, Heading, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useAuthContext } from '../../base/contexts/auth.context';

export const AuthLoginPage: React.FC = () => {

  const { Signin } = useAuthContext();

  return (
    <Box width={'100%'} height={'100%'} >
      <AbsoluteCenter p="4" axis="both">
        <VStack>          
          <Heading size="4xl">Kiddocare Admin Portal</Heading>
          <Text>Accesso consentito solo alle persone autorizzate</Text>
          <Button onClick={Signin}>Login</Button>
        </VStack>
      </AbsoluteCenter>
    </Box>
  );
  /*const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    // Esempio di autenticazione fittizia (inserisci qui la tua logica)
    if (username === 'admin' && password === 'password') {
      localStorage.setItem('auth', 'true'); // Salva lo stato di autenticazione
      navigate('/dashboard'); // Reindirizza alla dashboard
    } else {
      alert('Credenziali non valide');
    }
  };

  return (
    <div className="container">
      <h2>Login</h2>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}>Login</button>
    </div>
  );*/
};