
import { PaginationResponse } from '../../../../base/models/pagination-response.model';
import { Pediatrician } from '../../models/registry/pediatrician.model';
import { CoreService } from '../core.service';

export class CoreRegistryPediatricansService extends CoreService {
  
  private readonly pediatricsEndpoint: string = `${this.CoreBaseUrl}/registry/pediatrics`;

  public getPediatrics(): Promise<PaginationResponse<Pediatrician>> {
    return new Promise<PaginationResponse<Pediatrician>>((resolve, reject) => {
      setTimeout(() => {
        resolve({
          total: 100,
          pages: 10,
          page: 1,
          size: 10,
          items: [
            { id: 1, firstName: 'John', lastName: 'Doe', taxCode: 'ABC123', account: { id: 1, email: 'pippo@palla.it' } },
            { id: 2, firstName: 'John', lastName: 'Doe', taxCode: 'ABC123', account: { id: 1, email: 'pippo@palla.it' } },
            { id: 3, firstName: 'John', lastName: 'Doe', taxCode: 'ABC123', account: { id: 1, email: 'pippo@palla.it' } },
            { id: 4, firstName: 'John', lastName: 'Doe', taxCode: 'ABC123', account: undefined },
            { id: 5, firstName: 'John', lastName: 'Doe', taxCode: 'ABC123', account: { id: 1, email: 'pippo@palla.it' } },
            { id: 6, firstName: 'John', lastName: 'Doe', taxCode: 'ABC123', account: undefined },
            { id: 7, firstName: 'John', lastName: 'Doe', taxCode: 'ABC123', account: { id: 1, email: 'pippo@palla.it' } },
            { id: 8, firstName: 'John', lastName: 'Doe', taxCode: 'ABC123', account: undefined },
            { id: 9, firstName: 'John', lastName: 'Doe', taxCode: 'ABC123', account: { id: 1, email: 'pippo@palla.it' } },
            { id: 10, firstName: 'John', lastName: 'Doe', taxCode: 'ABC123', account: { id: 1, email: 'pippo@palla.it' } }
          ]
        });
      }, 1000);
      /*fetch(this.pediatricsEndpoint, {
        method: 'GET'
      }).then((response) => {
        this.log?.info('Fetching Pediatrics List', response);
        if (!response.ok) {
          throw new Error(response.status + ' - ' + response.statusText);
        }
        return response.json();
      }).then(data => resolve(data)).catch((err) => {
        this.log?.error('Error fetching pediatrics list', err);
        reject(err);
      });*/
    });
  }

  public getPediatrician(id: number): Promise<Pediatrician> {
    return new Promise<Pediatrician>((resolve, reject) => {
      setTimeout(() => {
        resolve({
          id: id,
          firstName: 'John',
          lastName: 'Doe',
          taxCode: 'ABC123',
          photo: 'https://via.placeholder.com/150',
          title: 'Dott.'
        });
      }, 1000);
    });
  }
}