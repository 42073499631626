import React from 'react';
import { useParams } from 'react-router-dom';

export const PediatriciansBillingsPage: React.FC = () => {
  const { id } = useParams();
  return (
    <div>
      <h3>Fatture del Pediatra (ID: {id})</h3>
      {/* Logica per visualizzare le fatture */}
    </div>
  );
}
