import { Box } from '@chakra-ui/react';
import React, { forwardRef, useImperativeHandle } from 'react';

interface CoreRegistryPediatricianAddProps {
  submitForm: () => void;
}

export const CoreRegistryPediatricianAdd = forwardRef<CoreRegistryPediatricianAddProps>((props, ref) => {
  useImperativeHandle(ref, () => ({
    submitForm() {
      console.log('Form submitted');
      // Logica per inviare il form
    },
  }));

  return (
    <Box>
      Form pediatra
    </Box>
  );
});