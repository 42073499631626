// src/services/Logger.ts
type LogLevel = 'debug' | 'info' | 'warn' | 'error';

class Logger {
  private static instances: Map<string, Logger> = new Map();
  private logLevel: LogLevel;
  private name: string;

  private constructor(name: string, level: LogLevel = 'debug') {
    this.name = name;
    this.logLevel = level;
  }

  public static getInstance(name: string, level: LogLevel = 'debug'): Logger {
    if (!Logger.instances.has(name)) {
      Logger.instances.set(name, new Logger(name, level));
    }
    return Logger.instances.get(name)!;
  }

  private shouldLog(level: LogLevel): boolean {
    const levels = { debug: 0, info: 1, warn: 2, error: 3 };
    return levels[level] >= levels[this.logLevel];
  }

  private getStyle(level: LogLevel): string {
    switch (level) {
      case 'debug':
        return 'color: #7840F5; font-weight: bold;';
      case 'info':
        return 'color: #00ACE6; font-weight: bold;';
      case 'warn':
        return 'color: #E4BF00; font-weight: bold;';
      case 'error':
        return 'color: #E4002D; font-weight: bold;';
      default:
        return '';
    }
  }

  public debug(message: string, ...args: any[]): void {
    if (this.shouldLog('debug')) {
      console.debug(`%c[${this.name}] DEBUG: ${message}`, this.getStyle('debug'), ...args);
    }
  }

  public info(message: string, ...args: any[]): void {
    if (this.shouldLog('info')) {
      console.info(`%c[${this.name}] INFO: ${message}`, this.getStyle('info'), ...args);
    }
  }

  public warn(message: string, ...args: any[]): void {
    if (this.shouldLog('warn')) {
      console.warn(`%c[${this.name}] WARN: ${message}`, this.getStyle('warn'), ...args);
    }
  }

  public error(message: string, ...args: any[]): void {
    if (this.shouldLog('error')) {
      console.error(`%c[${this.name}] ERROR: ${message}`, this.getStyle('error'), ...args);
    }
  }
}

export default Logger;
