import { AbsoluteCenter, Box, Spinner, VStack } from '@chakra-ui/react';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { jwtDecode} from 'jwt-decode';
import Logger from '../helpers/logger.helper';
import { User } from '../../modules/security/models/user.model';

// Tipi per il contesto
interface AuthContextType {
  ImLoading: boolean;
  User?: User;
  Signin: () => void;
  HandleRedirectCallback: () => void;
  GetUser: () => void;
  Logout: () => void;
}

// Contesto di autenticazione
const AuthContext = createContext<AuthContextType | undefined>(undefined);
const log = Logger.getInstance('AuthContext');

// Componente provider per il contesto
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  
  const [imLoading, setImLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | undefined>(undefined);

  const userManager = useMemo(() => {
    return new UserManager({
      authority: 'https://auth.kiddocare.eu/realms/kiddocare',
      client_id: 'portal-admin',
      client_secret: 'uC5JFs0sNdE2AZ7RIi5gbsy6DyO602Kn',
      redirect_uri: 'http://localhost:3000/auth/callback',
      post_logout_redirect_uri: 'http://localhost:3000/auth/postlogout',
      scope: 'openid profile email',
      response_type: 'code',
      userStore: new WebStorageStateStore({ store: window.localStorage })
    });
  }, []);

  const chekUser = (user: any) => {
    log.debug('CHECKUSER', user);
    if (user) { 
      const decodedToken: any = jwtDecode(user.access_token);
      console.log('decodedToken', decodedToken);
      setUser({
        Email: decodedToken.email,
        EmailVerified: decodedToken.email_verified,
        IdUser: decodedToken.sub,
        FirstName: decodedToken.given_name,
        LastName: decodedToken.family_name,
        Roles: decodedToken.realm_access.roles
      });
    }
    else { setUser(undefined);}
  };

  useEffect(() => {
    const getUser = async () => {
      const authenticatedUser = await userManager.getUser();
      chekUser(authenticatedUser);
      setImLoading(false);
    };
    getUser();
    userManager.events.addUserLoaded((user) => {
      console.log('User loaded:', user);
      // Puoi fare altre operazioni con il nuovo token
    });
  }, [userManager]);

  const value: AuthContextType = {
    ImLoading: imLoading,
    User: user,
    Signin: async () => {
      await userManager.signinRedirect();
    },
    HandleRedirectCallback: async () => {
      // Completa il processo di login dopo il callback di Keycloak
      const user = await userManager.signinRedirectCallback();
      chekUser(user);
      return user;
    },
    GetUser: async () => {
      // Ottieni l'utente autenticato
      const user = await userManager.getUser();
      chekUser(user);
      return user;
    },
    Logout: async () => {
      // Logout dell'utente
      await userManager.signoutRedirect();
      chekUser(undefined);
    }
  };

  if (imLoading) {
    return(
      <Box width={'100%'} height={'100%'} >
        <AbsoluteCenter p="4" axis="both">
          <VStack>
            <Spinner size={'xl'} />
          </VStack>
        </AbsoluteCenter>
      </Box>
    );
  } else {
    return (
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
    );
  }
};

// Hook per utilizzare il contesto
export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) { throw new Error('useAuth must be used within an AuthProvider'); }
  return context;
};
