import { AbsoluteCenter, Box, Button, Heading, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { NavLink } from 'react-router-dom';

export const AuthPostLogoutPage: React.FC = () => {
  return (
    <Box width={'100%'} height={'100%'} >
      <AbsoluteCenter p="4" axis="both">
        <VStack>          
          <Heading size="4xl">Kiddocare Admin Portal</Heading>
          <Text>Disconnesso correttamente dal sistema</Text>
            <Button asChild variant={'outline'}>
              <NavLink to={'/'}>Home</NavLink>
            </Button>  
        </VStack>
      </AbsoluteCenter>
    </Box>
  );
}