import { AbsoluteCenter, Box, Card, Em, Flex, Heading, HStack, Spinner, Stack, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { PiCertificateDuotone, PiHouseDuotone, PiInvoiceDuotone, PiPersonArmsSpreadDuotone, PiStethoscopeDuotone, PiUsersThreeDuotone } from 'react-icons/pi';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { ButtonLinkComponent } from '../../base/ui/button-link.component';
import { CoreRegistryPediatricansService } from '../../modules/core/services/registry/pediatricians.service';
import { Pediatrician } from '../../modules/core/models/registry/pediatrician.model';
import { Avatar } from '../../base/ui/avatar';
import { BreadcrumbCurrentLink, BreadcrumbLink, BreadcrumbRoot } from '../../base/ui/breadcrumb';
import Logger from '../../base/helpers/logger.helper';

const coreRPS = new CoreRegistryPediatricansService();
const log = Logger.getInstance('PediatriciansDetailPage');

export const PediatriciansDetailPage: React.FC = () => {

  const { id } = useParams();
  const [ pediatrician, setPediatrician] = useState<Pediatrician | undefined>(undefined);
  const location = useLocation();

  useEffect(()=> {
    coreRPS.getPediatrician(1).then((result) => {
      console.log('eccolo', result);
      setPediatrician(result);
    }).catch((err) => {
      console.error(err);
    });
  }, [id]);


  const getCurrentBreadcrumb = () => {
    const page = location.pathname.replace('/pediatricians/detail/' + pediatrician?.id, '');
    let name = 'Scheda';
    switch (page) {
      case '/patients': name = 'Pazienti'; break;
    }
    return (<BreadcrumbCurrentLink>{name}</BreadcrumbCurrentLink>);
  };
  
  if (pediatrician) {
    return (
      <Flex width={'100%'} height={'100%'} direction={{ base: 'column', md: 'row'}} colorPalette={'pediatrician'}>
        <Box bg={'bg.subtle'} borderColor={'border'} overflow={'auto'} borderEndWidth={{ base: 0, md: 1}} borderBottomWidth={{base: 1, md: 0}} p={3} minW={'250px'}>
          <VStack align={'start'} gap={3}>
            {/*<Heading paddingX={3} color={'gray.400'}>
              <HStack>
                <PiStethoscopeDuotone />
                <Text>Pediatra</Text>
              </HStack>
            </Heading>*/}

            <Card.Root marginBottom={'1'} width={'full'}>
              <Card.Body>
                <HStack gap="4">
                  <Avatar name={pediatrician.lastName + ' ' + pediatrician.firstName} size="sm"  />
                  <Stack gap="0">
                    <Text color="fg.muted" textStyle="sm">{pediatrician.title}</Text>
                    <Text fontWeight="medium">{pediatrician.lastName} {pediatrician.firstName}</Text>
                  </Stack>
                </HStack>
              </Card.Body>
            </Card.Root>

            <ButtonLinkComponent to='' colorPalette='pediatrician'>
              <HStack>
                <PiCertificateDuotone />
                <Text>Scheda</Text>
              </HStack>
            </ButtonLinkComponent>

            <ButtonLinkComponent to='patients'>
              <HStack>
                <PiPersonArmsSpreadDuotone />
                <Text>Pazienti</Text>
              </HStack>
            </ButtonLinkComponent>

            <ButtonLinkComponent to='consultations'>
              <HStack>
                <PiInvoiceDuotone />
                <Text>Consulti</Text>
              </HStack>
            </ButtonLinkComponent>

            <ButtonLinkComponent to='prescriptions'>
              <HStack>
                <PiCertificateDuotone />
                <Text>Prescrizioni</Text>
              </HStack>
            </ButtonLinkComponent>

            <ButtonLinkComponent to='certificates'>
              <HStack>
                <PiCertificateDuotone />
                <Text>Certificati</Text>
              </HStack>
            </ButtonLinkComponent>

            <ButtonLinkComponent to='bookings'>
              <HStack>
                <PiInvoiceDuotone />
                <Text>Prenotazioni</Text>
              </HStack>
            </ButtonLinkComponent>

            <ButtonLinkComponent to='billings'>
              <HStack>
                <PiCertificateDuotone />
                <Text>Fatturazioni</Text>
              </HStack>
            </ButtonLinkComponent>

          </VStack>
        </Box>
        <Box flexGrow={1} overflow={'auto'} p={4} overflowY={'auto'}>
          <BreadcrumbRoot separatorGap={4} size={'lg'}>
            <BreadcrumbLink asChild>
              <Link to="/">
                <PiHouseDuotone /> Home
              </Link>
            </BreadcrumbLink>
            <BreadcrumbLink asChild>
              <Link to="/pediatricians">
                <PiStethoscopeDuotone /> Pediatri             
              </Link>
            </BreadcrumbLink>
            <BreadcrumbLink asChild>
              <Link to={'/pediatricians/detail/' + pediatrician.id}>
                {pediatrician.lastName} {pediatrician.firstName}   
              </Link>
            </BreadcrumbLink>
            {getCurrentBreadcrumb()}
          </BreadcrumbRoot>
          <Outlet context={pediatrician} />
        </Box>
      </Flex>
    )
  } else {
    return (
      <Box width={'100%'} height={'100%'} >
        <AbsoluteCenter p="4" axis="both">
          <VStack colorPalette="teal">
            <Spinner size={'xl'} />
            <Text>Loading...</Text>
          </VStack>
        </AbsoluteCenter>
      </Box>
    );
  }
}
