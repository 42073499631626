import { Box, Card, Em, Flex, HStack, Icon, StatLabel, StatRoot, StatValueText, Text } from "@chakra-ui/react";
import React from "react";
import { LuDollarSign } from "react-icons/lu";
import { useOutletContext } from "react-router-dom";
import { Pediatrician } from "../../modules/core/models/registry/pediatrician.model";

export const PediatricianPatientsPage: React.FC = () => {
  // const { id } = useParams();
  const context = useOutletContext<Pediatrician | undefined>();
  return (
    <Box>
      <Text textStyle="sm" color={'gray.400'}><Em>Tutti i pazienti gestiti dal Pediatra</Em></Text>

    </Box>
  );
}
