import { Box, Flex, IconButton } from '@chakra-ui/react';
import React from 'react';
import { PiHouseDuotone, PiMoonDuotone, PiPersonArmsSpreadDuotone, PiStethoscopeDuotone, PiSunDimDuotone, PiUserListDuotone, PiUsersThreeDuotone } from 'react-icons/pi';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AuthCallbackPage } from './auth/callback.page';
import { AuthLoginPage } from './auth/login.page';
import { AuthPostLogoutPage } from './auth/postlogout.page';
import { DashboardPage } from './home/dashboard.page';
import { PediatriciansBillingsPage } from './pediatricians/billings.page';
import { PediatriciansCertificatesPage } from './pediatricians/certificates.page';
import { PediatricianCardPage } from './pediatricians/card.page';
import { PediatriciansDetailPage } from './pediatricians/detail.page';
import { PediatriciansPage } from './pediatricians/pediatricians.page';
import { useAppContext } from '../base/contexts/app.context';
import { useAuthContext } from '../base/contexts/auth.context';
import { ButtonLinkComponent } from '../base/ui/button-link.component';
import { PediatricianPatientsPage } from './pediatricians/patients.page';

export const Main: React.FC = () => {

  const { User } = useAuthContext();
  const { IsDarkMode, ToggleTheme } = useAppContext();

  return (
    <BrowserRouter future={{ v7_relativeSplatPath: true, v7_startTransition: true}}>
      <Routes>
        <Route path="/auth/*" element={
          <Routes>
            <Route path="login" element={<AuthLoginPage />} />
            <Route path="callback" element={<AuthCallbackPage />} />
            <Route path="postlogout" element={<AuthPostLogoutPage />} />
            <Route path="/" element={<Navigate to="login" replace />} />
          </Routes>
        } />          
        <Route path="/*" element={(
          User ? 
            <Flex direction={{ base: 'column', md: 'row'}} height="100vh" overflow={'hidden'} >
              <Box borderColor={'border'} borderEndWidth={{ base: 0, md: 1}} borderBottomWidth={{base: 1, md: 0}}>
                <Flex height={'100%'} direction={{ base: 'row', md: 'column'}} bg={'bg.muted'}>
                  <Flex flexGrow="0" direction={{ base: 'row', md: 'column'}} justify={'space-between'} gap={1} padding={2}>
                    <ButtonLinkComponent type='IconButton' to='/dashboard'>
                      <PiHouseDuotone />
                    </ButtonLinkComponent>
                    <ButtonLinkComponent type='IconButton' to='/accounts' isActiveVariant='outline' colorPalette='admin' title='Tutti gli account'>
                      <PiUserListDuotone />
                    </ButtonLinkComponent>
                    <ButtonLinkComponent type='IconButton' to='/pediatricians' isActiveVariant='outline' colorPalette='pediatrician' title='Tutti i pediatri'>
                      <PiStethoscopeDuotone />
                    </ButtonLinkComponent>
                    <ButtonLinkComponent type='IconButton' to='/parents' isActiveVariant='outline' colorPalette='parent' title='tutti i genitori'>
                      <PiUsersThreeDuotone />
                    </ButtonLinkComponent>
                    <ButtonLinkComponent type='IconButton' to='/patients' isActiveVariant='outline' colorPalette='patient' title='tutti i pazienti'>
                      <PiPersonArmsSpreadDuotone />
                    </ButtonLinkComponent>
                  </Flex>
                  <Box flexGrow="1"></Box>
                  <Flex flexGrow="0" direction={{ base: 'row', md: 'column'}} justify={'space-between'} gap={1} padding={2}>
                    <IconButton variant={'subtle'} onClick={ToggleTheme}>
                      {(IsDarkMode ? <PiSunDimDuotone /> : <PiMoonDuotone />)}
                    </IconButton>
                  </Flex>
                </Flex>
              </Box>
              <Box flexGrow={1}>
                <Routes>
                  <Route path="dashboard" element={<DashboardPage />} />
                  <Route path="pediatricians" element={<PediatriciansPage />} />
                  <Route path="pediatricians/detail/:id" element={<PediatriciansDetailPage />}>
                    <Route path="" element={<PediatricianCardPage />} />
                    <Route path="certificates" element={<PediatriciansCertificatesPage />} />
                    <Route path="billings" element={<PediatriciansBillingsPage />} />
                    <Route path="patients" element={<PediatricianPatientsPage />} />
                  </Route>
                  <Route path="/" element={<Navigate to="dashboard" replace />} />
                  <Route path="*" element={<h1>404 - Pagina non trovata</h1>} />
                </Routes>
              </Box>
            </Flex>
          : 
            <Navigate to="/auth" replace />
          )} 
        />
      </Routes>
    </BrowserRouter>
  );
}