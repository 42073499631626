import { Box, Button, Card, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { useAuthContext } from '../../base/contexts/auth.context';

export const DashboardPage: React.FC = () => {
  const { Logout, User } = useAuthContext();

  return (
    <Box p={4} colorPalette={'pediatrician'}>
      
      <Heading>Dashboard Kiddocare Admin Portal</Heading>

      <Card.Root>
        <Card.Body>
          <Text fontSize={'sm'}>Ciao:</Text>
          <Text fontSize={'lg'}>{User?.FirstName + ' ' + User?.LastName}</Text>
        </Card.Body>
        <Card.Footer>
          <Button onClick={Logout}>Logout</Button>
          <Button onClick={Logout} colorPalette={'gray'}>Logout</Button>
        </Card.Footer>
      </Card.Root>

    </Box>
  );
}
